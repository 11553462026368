import React from "react";
import { Button } from "antd";

const RejectButton = ({ title, onClick }) => {
  return (
    <>
      <Button
        type='button'
        className='reject_btn'
        htmlType='submit'
        onClick={onClick}
      >
        {title}
      </Button>
    </>
  );
};

export default RejectButton;
