import { Empty, Form, Modal } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import RejectButton from "../button/rejectButton";
import SaveButton from "../button/saveButton";
const AutoCompleteModalWrapped = styled.div`
  background: #fcfbfb;
  border: 1px solid rgba(168, 168, 168, 0.09);
  border-radius: 12px;
  overflow: auto;
  overflow-x: hidden;
  height: 300px;
`;

const AutoCompleteModalCard = ({ autoCompleteList }) => {
  return (
    <>
      {autoCompleteList.length == 0 ? (
        <AutoCompleteModalWrapped className='d-flex justify-content-center align-items-center'>
          <Empty />
        </AutoCompleteModalWrapped>
      ) : (
        <AutoCompleteModalWrapped>
          <div className='row'>
            <div className='col-md-12'>
              {autoCompleteList.map((item, index) => (
                <div className='style-card'>
                  <div className='card-style-sub'>
                    <div className='align-items-center'>
                      <div className='avatar-info'>
                        <span className='auto-complete-heading'>{item.value}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </AutoCompleteModalWrapped>
      )}
    </>
  );
};
export default AutoCompleteModalCard;
