import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { errorNotification } from "../components/notifications/alertNotification";
import { REQUEST_METHOD, STATUSES } from "../config/constants/defaultValues";
import { DASHBOARD_API } from "../config/constants/endPoints";
import { apiService } from "../config/services/api.service";


export const getDashboardRequest = createAsyncThunk(
    "dashboard/dashboardData",
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiService(
                DASHBOARD_API,
                REQUEST_METHOD.GET,
                null,
                true,
            );
            if (response.status === 200) {
                return response.data.data;
            } else {
                return errorNotification(Object.values(response.data.errors));
            }
        } catch (error) {
            errorNotification("Something went wrong")
            return rejectWithValue("Something went wrong");
        }
    }
);

const dashBoardSlice = createSlice({
    name: "dashboard",
    initialState: {
        dashboardData: null,
        status: STATUSES.IDLE,
        error: null,
    },
    extraReducers: {
        [getDashboardRequest.pending]: (state, action) => {
            state.status = STATUSES.LOADING;
        },
        [getDashboardRequest.fulfilled]: (state, action) => {

            state.status = STATUSES.IDLE;
            state.dashboardData = action.payload;
        },
        [getDashboardRequest.rejected]: (state, action) => {
            state.status = STATUSES.ERROR;
            state.error = action.payload;
        },
    },
});
export default dashBoardSlice.reducer;

